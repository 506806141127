@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #F9F9F9;
}

.container{
    max-width: 1200px;
}

.editor_cont ul li{
    list-style: circle !important;
    margin-left: 40px;
} 
.editor_cont ol{
    margin-left: 40px;
    list-style: decimal;
}